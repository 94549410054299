<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Contacts</h3>

			<div class="d-inline-flex align-center">
				<div v-if="search['show']" class="d-inline-block mr-3">
					<v-text-field class="fsk-v-input" v-model="search['input']" name="search" type="search" dense hide-details="auto" prepend-inner-icon="mdi-magnify" outlined clearable></v-text-field>
				</div>
				<v-btn v-if="!search['show']" icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="search['show'] = true"><v-icon>mdi-magnify</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click.stop="showForm"><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.name`]="{ item }">
							<div class="py-3">
								<div>{{ item['name'] }}</div>
								<div class="caption">{{ item['company'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.tags`]="{ item }">
							<div class="text-center">
								<v-chip v-if="item['tags']" :color="getTagsColor(item.tags)" x-small outlined>{{ item['tags'] }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-title>View / Edit</v-list-item-title>
									</v-list-item>
									<v-list-item dense @click="deleteItem(item)">
										<v-list-item-title>Delete</v-list-item-title>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>

		<!-- Create Dialog -->
		<v-dialog
			v-model="form['show']"
			persistent
			scrollable
			content-class="fsk-v-create-dialog"
		>
			<v-card :loading="form['loading']" :disabled="form['loading']">
				<v-card-title>
					<span v-if="form['create']" class="headline">New Contact</span>
					<span v-else class="headline">Edit Contact</span>
				</v-card-title>
				<v-card-text style="max-height: 80vh;">
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['name']" class="fsk-v-input" label="Name *" name="name" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['company']" class="fsk-v-input" label="Company *" name="company" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['phone']" class="fsk-v-input" label="Phone *" name="phone" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="form['data']['email']" class="fsk-v-input" label="Email *" name="email" type="text" outlined dense hide-details="auto"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4 px-6">
					<v-spacer></v-spacer>
					<v-btn color="grey" text @click="hideForm()">Close</v-btn>
					<v-btn color="primary" text @click="submitForm()">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete Dialog -->
		<v-dialog
			v-model="del['show']"
			persistent
			max-width="400"
		>
		<v-card :loading="del['loading']" :disabled="del['loading']">
			<v-card-title class="headline">Delete Contact</v-card-title>

			<v-card-text>Please confirm the deletion of contact <strong>{{ del['name'] }}</strong></v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="py-4 px-6">
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="hideDelete()">Close</v-btn>
				<v-btn color="primary" text @click="confirmDelete()">Confirm</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data: () => ({
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name | Company',
					align: 'start',
					value: 'name',
				},
				{
					text: 'Date',
					value: 'date',
				},
				{
					text: 'Phone',
					value: 'phone',
				},
				{
					text: 'Email',
					value: 'email',
				},
				{
					text: 'Tags',
					value: 'tags',
					align: 'center',
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			form: {
				create: true,
				show: false,
				loading: false,
				data: {
					id: '',
					name: '',
					company: '',
					phone: '',
					email: '',
					tags: '',
				}
			},
			del: {
				show: false,
				loading: false,
				id: '',
				name: ''
			}
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						name: 'Charles Cooper',
						company: 'Hangar Pvt. Ltd',
						phone: '9231457895',
						email: 'charles@hangar.com',
						tags: 'customer',
						date: new Date()
					},
					{
						id: 2,
						name: 'Stephen Clark',
						company: 'Smart Ltd',
						phone: '9231457895',
						email: 'stephen@smart.com',
						tags: 'lead',
						date: new Date(Date.now() - 86400000)
					},
					{
						id: 3,
						name: 'Russell Lee',
						company: 'Rus Corp',
						phone: '9231451423',
						email: 'russell@rcorp.com',
						tags: null,
						date: new Date(Date.now() - 3888000000)
					},
					{
						id: 4,
						name: 'Rachel Wood',
						company: 'CC Corp.',
						phone: '9212678992',
						email: 'rachel@ccc.com',
						tags: 'lead',
						date: new Date(Date.now() - 6480000000)
					},
					{
						id: 5,
						name: 'Joel Hill',
						company: 'WebTech',
						phone: '9212678992',
						email: 'joelbhill@rhyta.com',
						tags: 'lead',
						date: new Date(Date.now() - 7344000000)
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getTagsColor (status) {
				switch (status) {
					case 'lead':
						return 'orange'
					
					case 'customer':
						return 'blue'
						
					default:
						return 'grey'
				}
			},
			editItem(item) {
				// Assign form data
				this.form['data']['id'] = item['id'];
				this.form['data']['name'] = item['name'];
				this.form['data']['company'] = item['company'];
				this.form['data']['phone'] = item['phone'];
				this.form['data']['email'] = item['email'];
				this.form['data']['tags'] = item['tags'];

				// Show dialog
				this.showForm('edit');
			},
			deleteItem(item) {
				// Assign data
				this.del['id'] = item['id'];
				this.del['name'] = item['name'];

				// Show dialog
				this.del['show'] = true;
			},
			hideDelete() {
				// Reset data
				this.del['id'] = '';
				this.del['name'] = '';

				// Unset loading
				this.del['loading'] = false;

				// Hide dialog
				this.del['show'] = false;
			},
			confirmDelete() {
				// TODO: Add delete contact API
				console.log(this.del['id']);

				// Set loading
				this.del['loading'] = true;

				setTimeout(() => {
					// Hide dialog
					this.hideDelete();
				}, 3000);
			},
			resetForm() {
				this.form['data']['id'] = '';
				this.form['data']['name'] = '';
				this.form['data']['company'] = '';
				this.form['data']['phone'] = '';
				this.form['data']['email'] = '';
				this.form['data']['status'] = '';
			},
			showForm(type = 'create') {
				if (type == 'edit') {
					this.form['create'] = false;
				}
				else {
					this.form['create'] = true;
				}

				// Show Dialog
				this.form['show'] = true;
			},
			hideForm() {
				// Hide dialog
				this.form['show'] = false;

				// Unset loading
				this.form['loading'] = false;

				// Clear form
				this.resetForm();
			},
			submitForm() {
				if (this.form['create']) {
					// TODO: Add create contact API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				else {
					// TODO: Add edit contact API
					console.log(this.form['data']);

					// Set loading
					this.form['loading'] = true;
				}
				setTimeout(() => {
					// Hide dialog
					this.hideForm();
				}, 3000);
			}
		}
	}
</script>
